import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const BooksPage = ({ data }) => (
  <Layout>
    <div className="book-list">
      <div className="book-list__title"></div>
      {data.allBooksJson.edges.map(bookList => (
        <div key={bookList.node.id}>
          <div className="book-list__item">
            <h3 style={{ fontWeight: "400" }}>{bookList.node.name}</h3>
            <div>
              {bookList.node.books.map(book => (
                <div className="book-list__book-item">
                  <p>
                    {book.title} by {book.author}
                  </p>
                  <p>{book.date_finished}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  </Layout>
)

export default BooksPage

export const booksQuery = graphql`
  query {
    allBooksJson {
      edges {
        node {
          name
          books {
            title
            author
            date_finished
          }
        }
      }
    }
  }
`
