import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <Link className="header" to="/">
        {siteTitle}
      </Link>
    </div>
    <div className="links">
      <div>
        <Link className="header" to="/bio">
          Bio
        </Link>
      </div>
      <div>
        <Link className="header" to="/projects">
          Projects
        </Link>
      </div>
      {/* <div>
        <Link className="header" to="/blog">
          Blog
        </Link>
      </div> */}
      <div>
        <Link className="header" to="/books">
          Reading
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
